<template>
  <div class="conent">
    <Button
      type="primary"
      v-if="user_info.team_level == 0"
      @click="addModelShow = !addModelShow"
      class="upload"
      >上传APP</Button
    >
    <Table
      :loading="loading"
      max-height="600"
      style="overflow: visible"
      :columns="columns"
      :data="modelData"
    ></Table>
    <!--添加模型-->
    <Modal
      v-model="addModelShow"
      class-name="addModel-modal"
      title="上传apk"
      footer-hide
      @on-cancel="closeAddModel"
      :mask-closable="false"
    >
      <Form ref="addModel" label-position="top">
        <FormItem label="版本号 :" prop="apkVersions">
          <Input v-model="apkVersions" />
        </FormItem>
        <FormItem label="渠道 :" prop="ditch">
          <Input v-model="ditch" />
        </FormItem>
        <FormItem label="版本说明 :" prop="remark">
          <Input v-model="remark" type="textarea" />
        </FormItem>
        <FormItem label="是否强更 :">
          <i-switch
            v-model="isUpdate"
            :true-value="1"
            :false-value="0"
            size="large"
          >
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </FormItem>

        <FormItem label="上传apk文件:" prop="device_name">
          <Upload
            ref="OrUpLoad"
            name="multipartFile"
            :before-upload="onBefore"
            :show-upload-list="true"
            :format="['apk']"
            :on-format-error="handleFormatError"
            :on-error="OrthophotoError"
            :on-success="OrthophotoSuccess"
            :disabled="uploadDisabled"
            :on-progress="onProgress"
            multiple
            type="drag"
            :headers="{ token: token }"
            :data="upLoadFileData"
            :action="upURL"
            style="display: inline-block; width: 100%"
          >
            <div style="padding: 20px 0; width: 100%">
              <Icon
                type="ios-cloud-upload"
                size="52"
                style="color: #3399ff"
              ></Icon>
              <p style="font-size: 20px">点击或将文件拖动到这里进行上传</p>
              <p>(必须填写上方信息才可进行上传)</p>
            </div>
          </Upload>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import Api from "@/utils/api.js";

export default {
  data() {
    return {
      addModelShow: false,
      apkVersions: "",
      remark: "",
      isUpdate: 0,
      ditch: "",
      upLoadFileData: {
        apkName: "",
        apkVersions: "",
        remark: "",
        ditch: "",
        isUpdate: 0,
        eid: -1,
      },
      token: "",
      upURL: "",
      uploadDisabled: false,
      loading: false,
      columns: [
        {
          title: "包名",
          key: "apk_name",
          align: "center",
          tooltip: "true",
        },
        {
          title: "版本号",
          key: "apk_versions",
          align: "center",
        },
        {
          title: "上传日期",
          key: "creation_time",
          align: "center",
        },
        {
          title: "渠道",
          key: "ditch",
          align: "center",
          tooltip: "true",
        },
        {
          title: "版本说明",
          key: "remark",
          align: "center",
          tooltip: "true",
        },

        {
          title: "操作",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "info",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      window.open(params.row.apk_url);
                    },
                  },
                },
                "下载"
              ),
              h(
                "Poptip",
                {
                  props: {
                    placement: "bottom",
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "success",
                        size: "small",
                      },
                    },
                    "生成二维码"
                  ),
                  h(
                    "div",
                    {
                      slot: "title",
                    },
                    [
                      h("img", {
                        attrs: {
                          src: params.row.qr_code_url,
                        },
                        style: {
                          width: "160px",
                          height: "160px",
                        },
                      }),
                    ]
                  ),
                  h(
                    "div",
                    {
                      slot: "content",
                    },
                    [
                      h(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: params.row.qr_code_url,
                            download: "JFAPP下载二维码",
                          },
                        },
                        "保存图片"
                      ),
                      h(
                        "span",
                        {
                          style: {
                            cursor: "pointer",
                            marginLeft: "20px",
                          },
                          on: {
                            click: () => {
                              //   console.log(params.row.apk_url);
                              let oInput = document.createElement("input");
                              oInput.value = params.row.apk_url;
                              document.body.appendChild(oInput);
                              oInput.select(); // 选择对象
                              document.execCommand("Copy"); // 执行浏览器复制命令
                              oInput.style.display = "none";
                              this.$Message.success("已复制到剪切板");
                              document.body.removeChild(oInput);
                            },
                          },
                        },
                        "复制地址"
                      ),
                    ]
                  ),
                ]
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  style: {
                    marginLeft: "10px",
                  },
                  on: {
                    click: () => {
                      this.$post(Api.delApk(), {
                        apk_id: params.row.apk_id,
                        apk_name: params.row.apk_name,
                      }).then((res) => {
                        if (res.code === 1) {
                          this.$Message.success(res.msg_customer);
                          this.getApkList();
                        } else {
                          this.$Message.error(res.msg_customer);
                        }
                      });
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      modelData: [],
      user_info: "",
    };
  },
  mounted() {
    this.user_info = JSON.parse(sessionStorage.getItem("user_info"));
    this.token = sessionStorage.getItem("token");
    this.upURL = Api.upAPK();
    this.getApkList();
  },
  methods: {
    // 获取列表
    getApkList() {
      this.loading = true;
      this.modelData = [];
      this.$post(Api.getApkAll()).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.modelData = res.data;
        } else {
          this.$Message.error("获取apk列表错误");
        }
        this.loading = false;
      });
    },
    // 开始上传前,选中文件后执行
    onBefore(file) {
      if (!this.apkVersions || !this.remark || !this.ditch) {
        this.$Message.warning("请先填写版本信息");
        return false;
      }
      this.upLoadFileData = {
        apkName: file.name,
        apkVersions: parseInt(this.apkVersions),
        remark: this.remark,
        ditch: this.ditch,
        isUpdate: this.isUpdate,
        eid: sessionStorage.getItem("Eid") ? sessionStorage.getItem("Eid") : -1,
      };
      let promise = new Promise((resolve) => {
        this.$nextTick(function () {
          resolve(true);
        });
      });
      return promise;
    },
    // 上传
    handleFormatError() {
      this.$Notice.warning({
        title: "文件格式不正确",
        desc: "文件格式请选择apk.",
      });
    },
    OrthophotoError(error) {
      this.uploadDisabled = false;
      this.$Message.error("上传失败");
      console.log(error);
    },
    // 开始文件上传,禁用上传组件
    onProgress() {
      this.uploadDisabled = true;
    },
    OrthophotoSuccess(res) {
      console.log("添加111", res);
      this.$Message.success("上传成功");
      this.getApkList();
      this.closeAddModel();
    },
    // 关闭添加框,清空数据
    closeAddModel() {
      this.uploadDisabled = false;
      this.apkVersions = "";
      this.remark = "";
      this.isUpdate = 0;
      this.ditch = "";
      this.addModelShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.conent {
  width: 100%;
  height: 100%;
  padding: 16px;
  .upload {
    margin-bottom: 10px;
  }
}
</style>